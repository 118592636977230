import React, { useEffect } from 'react';
import { Stack, getTheme } from '@fluentui/react';
import { Message as ApiMessage } from '../utils/api';
import { Message } from './Message';
import { IMessageProps } from './Message';
import { Card, makeStyles, shorthands } from '@fluentui/react-components';

const theme = getTheme();
const useStyles = makeStyles({
  questionsContainer: {
    ...shorthands.gap('20px'),
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'minmax(50px, auto)',
    '@media (max-width: 576px)': {
      gridTemplateColumns: '1fr',
    },
  },
  question: {
    justifyContent: 'center',
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    paddingLeft: '24px',
    paddingTop: '12px',
    boxSizing: 'border-box',
  },
});

export interface ISampleQuestionsContainer {
  questions: string[];
  onClick: (question: string, index: number) => void;
}

export const SampleQuestionsContainer = ({
  questions,
  onClick,
}: ISampleQuestionsContainer) => {
  const styles = useStyles();

  if (questions.length === 0) {
    return null;
  }

  return (
    <div className={styles.questionsContainer}>
      {questions.map((question, index) => (
        <Card
          data-testid={'Question'+index}
          key={index}
          className={styles.question}
          onClick={() => {
            onClick(question, index);
          }}
        >
          {question}
        </Card>
      ))}
    </div>
  );
};

export interface IMessageContainerProps {
  messages: IMessageProps[];
}

export const MessageContainer = ({ messages }: IMessageContainerProps) => {
  const bottomRef = React.useRef<HTMLDivElement>(null);
  // Scroll to bottom when rendering new messages
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const getMsgId = (msgObj?: ApiMessage): string => {
    if (msgObj && msgObj.id) {
      return msgObj.id;
    }
    console.log('ERROR: No msg obj pass to getMsgId or msg obj has no id');
    console.log(msgObj);
    return '';
  };

  return (
    <>
      <Stack
        id="message-container"
        verticalFill
        horizontalAlign="center"
        styles={{
          root: {
            flex: '1 1 0',
            borderColor: theme.palette.neutralSecondary,
            width: '100%',
            overflowY: 'auto',
            minHeight: '45vh',
          },
        }}
      >
        {messages.map((msgProp, index) => (
          <Stack.Item
            data-testid={`${msgProp.msgObj?.role}-${Math.trunc(index / 2) + 1}`}
            key={getMsgId(msgProp.msgObj)}
            align={'start'}
            styles={{ root: { maxWidth: '90%', minWidth: '23rem' } }}
          >
            <Message {...msgProp} />
          </Stack.Item>
        ))}
        <div ref={bottomRef}></div>
      </Stack>
    </>
  );
};
