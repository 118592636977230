import {
  Field,
  RadioGroup,
  Radio,
  Spinner,
  FluentProvider,
  webLightTheme,
  Textarea,
  Text,
  Link,
  makeStyles,
  Dialog,
  DialogActions,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  DialogBody,
  DialogContent,
  Button,
} from '@fluentui/react-components';
import { useState, useContext } from 'react';
import { CommentData, ApiClient, Conversation } from '../utils/api';
import { ApiClientContext } from '../contexts/ApiClient';
import { Dismiss24Regular } from "@fluentui/react-icons";

import { PRIVACY_URL } from '../constants/env';

const useStyles = makeStyles({
  dialog: {
    maxWidth: '44%',
  },
  fieldLabel: {
    color: '#000',
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '1.375rem',
    marginTop: '3rem',
  },
  radio: {
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
  },
  textarea: {
    height: '9.8rem',
  },
  text: {
    fontSize: '1rem',
    fontWeights: '400',
    lineHeight: '1.125rem',
    marginTop: '1.5rem',
    marginBottom: '3.5rem',
  },
  link: {
    color: '#000',
    fontSize: '1rem',
    fontWeights: '400',
    lineHeight: '1.125rem',
    textDecorationLine: 'underline',
  },
  errMsg: {
    color: 'red',
    fontSize: '1rem',
    fontWeights: '400',
    lineHeight: '1.125rem',
    wordWrap: 'break-word',
    overflowX: 'hidden',
  },
  submitBtn: {
    backgroundColor: '#174AE4',
    width: '8.56rem',
    height: '2.56rem',
    paddingLeft: '3.21rem',
    paddingRight: '3.21rem',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    fontWeight: '400',
    fontSize: '0.75rem',
  },
  cancelBtn: {
    width: '8.56rem',
    height: '2.56rem',
    paddingLeft: '3.21rem',
    paddingRight: '3.21rem',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    fontWeight: '400',
    fontSize: '0.75rem',
  },
});

export interface ICommentProps {
  isOpen: boolean;
  commentData: CommentData;
  onCommentSubmitted: () => void;
  onCloseComment: () => void;
}

export const CommentModal = ({
  isOpen,
  commentData,
  onCommentSubmitted,
  onCloseComment,
}: ICommentProps) => {
  const [comment, setComment] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [reason, setReason] = useState('');
  const [shareData, setShareData] = useState('');
  const apiClient = useContext(ApiClientContext) as ApiClient;

  const styles = useStyles();

  const dialogContent = {
    title: 'Give feedback to Microsoft',
    likeTextareaHeading: 'Tell us more about what went well:',
    dislikeTextareaHeading: 'Tell us more about what went wrong:',
  };

  const resetInput = () => {
    setComment('');
    setReason('');
    setShareData('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
  };

  const submit = async () => {
    setErrMsg('');
    setShowSpinner(true);
    commentData.comment = comment;
    if (shareData !== 'yes') {
      commentData.answer = '';
      commentData.question = '';
      commentData.references = [];
      commentData.conversation = {} as Conversation;
    }

    if (!commentData.is_like) {
      commentData.dislike_reason = reason;
    }

    try {
      await apiClient.sendComment(commentData);
      // Notify liked/disliked
      onCommentSubmitted();
      // Close form
      close();
    } catch (err) {
      const msg = `Error sending comment: ${err}`;
      console.log(msg);
      setErrMsg(msg);
    } finally {
      // Reset spinner state
      setShowSpinner(false);
    }
  };

  const close = () => {
    resetInput();
    setErrMsg('');
    // Notify close
    onCloseComment();
  };

  return (
    <FluentProvider theme={webLightTheme}>
      <Dialog modalType="modal" open={isOpen}>
        <DialogSurface className={styles.dialog} data-testid="comment-modal">
          <form onSubmit={handleSubmit}>
            <DialogBody>
              <DialogTitle
                action={
                  <DialogTrigger action="close">
                    <Button
                      appearance="subtle"
                      aria-label="close"
                      icon={<Dismiss24Regular color="#174AE4" />}
                      onClick={() => close()}
                    />
                  </DialogTrigger>
                }
              >
                {dialogContent.title}
              </DialogTitle>
              <DialogContent>
                {!commentData.is_like && (
                  <Field
                    label="The response was:"
                    className={styles.fieldLabel}
                  >
                    <RadioGroup
                      value={reason}
                      onChange={(_, data) => setReason(data.value)}
                    >
                      <Radio
                        className={styles.radio}
                        value="Inaccurate response"
                        label="Inaccurate response"
                      />
                      <Radio
                        className={styles.radio}
                        value="Lacking Details"
                        label="Lacking Details"
                      />
                      <Radio
                        className={styles.radio}
                        value="Other"
                        label="Other"
                      />
                    </RadioGroup>
                  </Field>
                )}
                <Field
                  label={
                    commentData.is_like
                      ? dialogContent.likeTextareaHeading
                      : dialogContent.dislikeTextareaHeading
                  }
                  className={styles.fieldLabel}
                >
                  <Textarea
                    className={styles.textarea}
                    data-testid="textarea-comment"
                    value={comment}
                    onChange={(_, data) => setComment(data.value)}
                  />
                </Field>
                <Field
                  required
                  label="Share my chat prompt, response, and diagnostic data for this interaction with Microsoft?"
                  className={styles.fieldLabel}
                >
                  <RadioGroup
                    required
                    value={shareData}
                    onChange={(_, data) => setShareData(data.value)}
                  >
                    <Radio
                      data-testid="sharedata-yes"
                      className={styles.radio}
                      value="yes"
                      label="Yes"
                    />
                    <Radio
                      data-testid="sharedata-no"
                      className={styles.radio}
                      value="no"
                      label="No"
                    />
                  </RadioGroup>
                </Field>
                <Field>
                  <Text className={styles.text}>
                    Understand more about what you are sharing as diagnostic
                    data <br />
                    By pressing Submit, your feedback will be used to improve
                    Microsoft products and services.{' '}
                    <Link
                      className={styles.link}
                      target="_blank"
                      href={PRIVACY_URL}
                    >
                      Privacy statement
                    </Link>
                  </Text>
                </Field>
                {errMsg === '' ? null : (
                  <Field>
                    <Text weight="medium" className={styles.errMsg}>
                      {errMsg}
                    </Text>
                  </Field>
                )}
              </DialogContent>
              {!showSpinner && (
                <DialogActions>
                  <Button
                    className={styles.submitBtn}
                    data-testid="submit-btn"
                    type="submit"
                    appearance="primary"
                    shape="circular"
                  >
                    Submit
                  </Button>
                  <Button
                    className={styles.cancelBtn}
                    appearance="secondary"
                    shape="circular"
                    onClick={() => close()}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              )}
            </DialogBody>
          </form>
          {showSpinner && (
            <Field>
              <Spinner
                appearance="primary"
                label="sending..."
                labelPosition="after"
              />
            </Field>
          )}
        </DialogSurface>
      </Dialog>
    </FluentProvider>
  );
};
