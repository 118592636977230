import { getTheme, Stack, Text , Link, Icon } from '@fluentui/react';
import { Footer } from "../components/Footer"
// Fluent UI 2 components
import {
    makeStyles,
    shorthands,
    Button
  } from '@fluentui/react-components';
import { PATH_AUTH, PATH_HOME } from '../constants/routes';
import { useNavigate } from 'react-router-dom';

const theme = getTheme();

const stackTokens = {
  smallGap: { childrenGap: '10px' },
  largeGap: { childrenGap: '2rem' },
  mediumStack: { childrenGap: '1rem', padding: '25px 0 0 0' },
};

const useStyles = makeStyles({
  errorText: { 
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('5px'),
    backgroundColor: theme.semanticColors.errorBackground,
  },
  headerText: {
    fontWeight: 600,
    fontSize: '4.5rem',
    lineHeight: 'normal',
  },
  centeredStack: {
    justifyContent: 'center', 
    alignItems: 'center',     
    minHeight: '300px',
    minWidth: '300px',
  },
  horizontalStack: {
    justifyContent: 'center', 
    alignItems: 'center',     
    flexDirection: 'row',
  },
  button: {
    marginTop: '20px',
    ...shorthands.padding('6px', '10px'),
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.white,
    backgroundColor: theme.palette.themePrimary,
    ...shorthands.borderRadius('5px'),
    cursor: 'pointer',
    boxShadow: `${theme.effects.elevation4}`,
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      backgroundColor: theme.palette.themeDarkAlt,
    },
  },
  icon: {
    fontSize: '16px',
  },
  footer: {
    justifyContent: 'center', 
    alignItems: 'center',     
    marginBottom: '4.37rem',
  },
});

export interface IErrorsProps {
    appTitle: string;
    logoutButton?: boolean;
    children?: React.ReactNode;
    }

export const Errors = ({ appTitle, logoutButton = false, children }: IErrorsProps) => {
    const styles = useStyles();
    const navigate = useNavigate();
    return (
        <Stack verticalAlign="center" verticalFill data-testid="home-component">
          <Stack
            className={styles.centeredStack}
            verticalFill
            tokens={stackTokens.smallGap}
          >
            <Stack
              className={styles.horizontalStack}
              tokens={stackTokens.largeGap}
            >
              <Text styles={{ root: styles.headerText }} data-testid="app-title">
                {appTitle}
              </Text>
            </Stack>      
              <Text styles= {{ root: styles.errorText }}>
                {children && (
                    <div>
                        {children}
                    </div>
                )}
                {logoutButton && (
                  <Link onClick={() => navigate(PATH_AUTH.logout)}> Sign out</Link>
                )}
              </Text>
              <Button className={styles.button} onClick={() => navigate(PATH_HOME.root)}>
                <Icon iconName="Home" className={styles.icon} /> Back to Home
              </Button>   
          </Stack>
          <Stack className={styles.footer}>
            <Footer/>
          </Stack>
        </Stack>
      );

}



