import { DefaultButton, IButtonProps, Spinner, SpinnerSize, TooltipHost, ActionButton, Stack, Text } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useState, useEffect } from 'react';



export const ShowDebugInfoButton = (props: IButtonProps) => {
  const tooltipId = useId('tooltip');

  const [iconProps, setIconProps] = useState({ iconName: "Info", style: { color: '#fff' } })
  const mouseEnt = () => {
    setIconProps({ iconName: "InfoSolid", style: { color: '#fff' } })
  }

  const mouseLev = () => {
    setIconProps({ iconName: "Info", style: { color: '#fff' } })
  }
  return (
    <TooltipHost
      content="Show debug info"
      id={tooltipId}
      calloutProps={{ gapSpace: 0 }}
      styles={{ root: { display: 'inline-block' } }}
    >
      <ActionButton iconProps={iconProps}  {...props}
        onMouseEnter={() => mouseEnt()}
        onMouseLeave={() => mouseLev()}
      />
    </TooltipHost>
  )
}


export interface ISendButtonProps extends IButtonProps {
  loading?: boolean
}

export const SendButton = ({ loading, ...rest }: ISendButtonProps) => {
  const btnWidth = '2rem';
  const btnStyles = {
    root: {
      borderRadius: '0.25rem',
      fontSize: '16px',
      height: '20px',
      maxWidth: btnWidth,
      minWidth: btnWidth,
      color: '#605e5c',
      border: 'none',
      background: 'none'
    },
  }
  return (

    <Stack
      styles={{ root: { marginLeft: 'auto', marginRight: '20px', flexFlow: 'nowrap' } }}
    >
      <div style={{ borderLeft: '1px solid #E0E0E0', height: '20px', position: 'relative', marginRight: '2.5px' }}> </div>
      <div style={{width:'32px',height:'20px'}}>
        {loading ?
          <Spinner size={SpinnerSize.small} /> :
          <DefaultButton data-testid="prompt-submit-btn" iconProps={{ iconName: 'Send' }} allowDisabledFocus styles={btnStyles} {...rest} />
        }
      </div>
    </Stack>
  )
}

export interface IShowReferenceButtonProps extends IButtonProps {
  number: number
  onToggle: (v: boolean) => void
}

export const ShowReferenceButton = ({ number, onToggle, checked, ...rest }: IShowReferenceButtonProps) => {
  const iconShowedUnfocused = 'ChevronDown';
  const iconShowedFocused = 'ChevronDownSmall';
  const iconHiddenUnfocused = 'ChevronRight';
  const iconHiddenFocused = 'ChevronRightSmall';

  const tooltipId = useId('tooltip');

  const [showed, { toggle: setShowed }] = useBoolean(false);
  const [iconName, setIconName] = useState(iconHiddenUnfocused);

  const mouseEnt = () => {
    setIconName(showed ? iconShowedFocused : iconHiddenFocused);
  }
  const mouseLev = () => {
    setIconName(showed ? iconShowedUnfocused : iconHiddenUnfocused);
  }

  useEffect(() => {
    onToggle(showed);
    setIconName(showed ? iconShowedUnfocused : iconHiddenUnfocused);
  }, [showed, onToggle])

  const refText = `${number.toFixed(0)} Related Document${number > 1 ? 's' : ''}`;

  return (
    <TooltipHost
      content="Show references"
      id={tooltipId}
      calloutProps={{ gapSpace: 0 }}
    >
      <ActionButton
        {...rest}
        toggle
        checked={checked || showed}
        onClick={setShowed}
        iconProps={{ iconName: iconName, style: { color: '#000000', margin: '0px 4px 0px 0px' } }}
        styles={{ root: { padding: 0 } }}
        onMouseEnter={() => mouseEnt()}
        onMouseLeave={() => mouseLev()}
      >
        <Text styles={{ root: { color: '#000000', fontWeight: '700' } }}>{refText}</Text>
      </ActionButton>
    </TooltipHost>
  )
}
