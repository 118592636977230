function path(root: string, sublink: string) {
  return `${root}${sublink}`;
};
  
const ROOTS_HOME = '/';
const ROOTS_ERRORS = '/errors';
const ROOTS_CHAT = '/chat';
const ROOTS_AUTH = '/auth';
const ROOTS_ONBOARDING = '/onboarding';

export const PATH_CHAT = {
  root: ROOTS_CHAT,
};

export const PATH_HOME = {
  root: ROOTS_HOME,
};
  
export const PATH_ERROR = {
  root: ROOTS_ERRORS,
  error400: path(ROOTS_ERRORS, '/400'),
  error401: path(ROOTS_ERRORS, '/401'),
  error403: path(ROOTS_ERRORS, '/403'),
  error404: path(ROOTS_ERRORS, '/404'),
  error500: path(ROOTS_ERRORS, '/500'),
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  logout: path(ROOTS_AUTH, '/logout'),
};

export const PATH_ONBOARDING = {
  root: ROOTS_ONBOARDING,
  withCode: (code: string) => `${ROOTS_ONBOARDING}?code=${encodeURIComponent(code)}`,
};