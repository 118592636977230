import {
    FluentProvider,
    webLightTheme,
    makeStyles,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    Button,
  } from "@fluentui/react-components";
  import { useState, useEffect } from "react";
  import { Dismiss24Regular } from "@fluentui/react-icons";

  const useStyles = makeStyles({
    faqDialog: {
      maxWidth: '85%',
    },
    faqHeading: {
      fontSize: '1rem',
      fontWeights: '600',
      lineHeight: '1.375rem',
      textDecorationLine: 'underline',
    },
    faqContent: {
      fontSize: '0.9rem',
      fontWeights: '400',
      lineHeight: '1.25rem',
    },
  });

  export interface IFAQProps {
    open: boolean;
    onClose?: () => void;
  }
  
  export const FAQ = ({ open, onClose }: IFAQProps) => {
    const styles = useStyles();
    const [openState, setOpenState] = useState(false);

    useEffect(() => {
      setOpenState(open);
    }, [open]);

    const closeDialog = () => {
      setOpenState(false);
      if (onClose) {
        onClose();
      }
    };

    return (
      <FluentProvider theme={webLightTheme}>
        <Dialog modalType="modal" open={openState}>
          <DialogSurface className={styles.faqDialog}>
            <DialogBody>
              <DialogTitle
                action={
                  <DialogTrigger action="close">
                    <Button
                      appearance="subtle"
                      aria-label="close"
                      icon={<Dismiss24Regular color="#174AE4" />}
                      onClick={() => closeDialog()}
                    />
                  </DialogTrigger>
                }
              >
                Project Edgefield: Responsible AI FAQ
              </DialogTitle>
              <DialogContent>
                <span className={styles.faqHeading}>
                  What is Project Edgefield?
                </span>
                <ul className={styles.faqContent}>
                  <li>
                    <strong>Project Edgefield</strong> is an AI-powered
                    assistant designed specifically for our EPSO partners. Its
                    purpose is to enhance and accelerate the experience of
                    building quality devices and solutions on Microsoft
                    technologies. It takes inputs in the form of text prompts
                    and outputs responses to the prompts grounded in a curated
                    set of information provided by the EPSO team.
                  </li>
                </ul>
                <span className={styles.faqHeading}>
                  What can Project Edgefield do?
                </span>
                <ul className={styles.faqContent}>
                  <li>
                    Answer partner queries on Windows IoT, Windows Server and Azure Stack
                    HCI.
                  </li>
                  <li>
                    Provide documentation, links, and resources for partner
                    development and deployment of our defined list of
                    technologies.
                  </li>
                  <li>
                    Summarize complex Microsoft Curated documents into concise
                    summaries.
                  </li>
                  <li>
                    Search and rank these Microsoft documents based on partner
                    context and intent.
                  </li>
                  <li>
                    Provide text responses in supported languages, specifically
                    Japanese and English.
                  </li>
                </ul>
                <span className={styles.faqHeading}>
                  What is Project Edgefield’s intended use(s)?
                </span>
                <ul className={styles.faqContent}>
                  <li>
                    Project Edgefield is intended to be used for answering
                    questions on our defined technologies (Windows IoT, Windows Server and Azure Stack
                      HCI)
                  </li>
                  <li>
                    Any information shared by Project Edgefield is considered
                    under the NDA signed with Microsoft. Partners can reach out
                    to their EPSO contacts to have the information reviewed for
                    ability to be shared with a broader audience.
                  </li>
                  <li>
                    Project Edgefield is intended to be used in Japanese and
                    English, but other languages can be output. These other
                    languages are machine translated and their responses are not
                    validated by Microsoft
                  </li>
                </ul>
                <span className={styles.faqHeading}>
                  How was Project Edgefield evaluated? What metrics are used to
                  measure performance?
                </span>
                <ul className={styles.faqContent}>
                  <li>
                    Project Edgefield was evaluated for the following:
                    <ul>
                      <li>
                        Fit for Purpose: Ability of the system to respond to
                        common partner questions
                      </li>
                      <li>
                        “Groundedness”: Ability of the system to only respond to
                        queries using a strict dataset
                      </li>
                      <li>
                        Red-Teaming: Ability of the system to reject prompts
                        that would have it violate its intended uses or result
                        in harmful information being output by the AI model.
                      </li>
                    </ul>
                  </li>
                </ul>
                <span className={styles.faqHeading}>
                  What are the limitations of Project Edgefield? How can users
                  minimize the impact of Project Edgefield’s limitations when
                  using the system?
                </span>
                <ul className={styles.faqContent}>
                  <li>
                    Know that the system leverages AI, and therefore has the
                    risk of producing inaccurate information. Validate all
                    information output by the system.
                  </li>
                  <li>
                    Over the course of a long conversation, the bot can lose
                    context as the number of tokens in the conversation exceeds
                    its context window.
                    <ul>
                      <li>
                        To minimize – keep conversations concise, and when the
                        system starts losing context, start a new conversation.
                      </li>
                    </ul>
                  </li>
                  <li>
                    The system does not persist conversations beyond the current
                    session
                    <ul>
                      <li>
                        To minimize – ensure any critical information from the
                        system is noted down
                      </li>
                    </ul>
                  </li>
                  <li>
                    The system is only validated with Japanese and English, but
                    has the capability to prompt and receive responses in many
                    additional languages through machine translation that have
                    not been validated for accuracy.
                    <ul>
                      <li>
                        To minimize – Use only validated languages in prompts.
                      </li>
                    </ul>
                  </li>
                </ul>
                <span className={styles.faqHeading}>
                  What operational factors and settings allow for effective and
                  responsible use of Project Edgefield?
                </span>
                <ul className={styles.faqContent}>
                  <li>
                    Ensure you are specific with what technology you are working
                    with (i.e. Windows IoT) to ensure the system effectively
                    routes your request to the correct search mechanism
                  </li>
                  <li>
                    Know that the system leverages AI, and therefore has the
                    risk of producing inaccurate information. Validate all
                    information output by the system.
                  </li>
                </ul>
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </FluentProvider>
    );
  };