import { useContext, useEffect } from 'react';
import { Stack, Text, IContextualMenuProps } from '@fluentui/react';
import { ReactComponent as MsLogo } from '../assets/ms_logo.svg';
import { IconButton } from '@fluentui/react/lib/Button';
import { UserContext, UserContextType } from '../contexts/UserContext';
import { VersionContext, VersionContextType } from '../contexts/VersionContext';
import { SelectedSkillContext } from '../contexts/SelectedSkillContext';
import { FluentProvider, webLightTheme, makeStyles, shorthands, Button } from "@fluentui/react-components";
import { ApiClientContext } from '../contexts/ApiClient';
import { ApiAuthenticationError, ApiAuthorizationError, ApiClient } from '../utils/api';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_ERROR } from '../constants/routes';

const useStyles = makeStyles({
  provider: {
    backgroundColor: '#FAFAFA',
    width: "100%",
  },
  main: {
    ...shorthands.gap("48px"),
    display: "flex",
    marginBottom: "2rem",
  },
  button: {
    backgroundColor: '#174AE4',
    width: '14rem',
    height: '3.125rem',
    paddingLeft: '2.62rem',
    paddingRight: '2.62rem',
    paddingTop: '0.81rem',
    paddingBottom: '0.81rem',
    fontSize: '1rem',
    marginRight: '2rem',
    "@media (max-width: 576px)": {
      marginRight: '0.5rem',
      width: '12rem',
      paddingLeft: '0rem',
      paddingRight: '0rem',
    }
  },

  card: {
    width: "100%",
    maxWidth: "100%",
    height: "fit-content",
    paddingLeft: '24px',
    paddingTop: '12px',
  }
});

export interface INavigationBarProps {
  scenario: 'wait_select_skill' | 'skill_selected' | 'conversation';
  onContactEPSOSMEClick: () => void;
}


export const NavigationBar = ({ scenario, onContactEPSOSMEClick }: INavigationBarProps) => {
  const styles = useStyles();
  const { userData, setUserData } = useContext(UserContext) as UserContextType;
  const { versionData, setVersionData } = useContext(VersionContext) as VersionContextType;
  const apiClient = useContext(ApiClientContext) as ApiClient;
  const selectedSkill = useContext(SelectedSkillContext);
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  
  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'signOut',
        text: 'Sign Out',
        onClick: () => navigate(PATH_AUTH.logout, { replace: true }),
        iconProps: { iconName: 'SignOut' },
      },
      {
        key: 'version',
        text: 'Version: ',
        disabled: true,
        style: { fontSize: '0.8rem' },
        iconProps: { iconName: 'Info' },
      },
    ],
    directionalHintFixed: true,
  };


  menuProps.items[1].text = 'Version: ' + versionData?.version

  // If the page is refreshed, the component will be re-rendered, setUserData again
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [versionResponse, userResponse] = await Promise.all([
          apiClient.getApiVersion(),
          apiClient.getUserInfo(),
        ]);

        setVersionData(versionResponse);
        setUserData(userResponse);

      } catch (error) {
        if (error instanceof ApiAuthenticationError) {
          navigate(PATH_ERROR.error401, { 
            state: { errMsg: error.message } 
          });
        } else if (error instanceof ApiAuthorizationError) {
          navigate(PATH_ERROR.error403, { 
            state: { errMsg: error.message } 
          });
        } else {
          console.error('Error fetching data:', error);
          navigate(PATH_ERROR.error500, { 
            state: { errMsg: 'Error Fetching Data: ' + error } 
          });
        }
      }
    };
    if (isAuthenticated && accounts.length > 0) {
      fetchData();
    }
  }, [apiClient, setUserData, setVersionData, isAuthenticated, accounts, navigate]);


  return (
    <Stack
      id="navbar"
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={{ root: { width: '100%', marginTop: '1.5rem', minHeight: "3rem" } }}
    >
      <Stack
        id="msft-logo-and-preview"
        horizontal
        verticalAlign="center"
        horizontalAlign="start"
        tokens={{ childrenGap: '0.81rem' }}
        styles={{ root: { marginLeft: '2.06rem' } }}
      >
        <Stack id="msft-logo">
          <MsLogo style={{ width: '7.25rem', height: 'auto' }} />
        </Stack>
        <Stack
          id="preview"
          verticalAlign="center"
          horizontalAlign="center"
          styles={{
            root: {
              width: '6.75rem',
              height: '1.75rem',
              backgroundColor: 'rgba(217, 217, 217, 0.22)',
              borderRadius: '1.125rem',
            },
          }}
        >
          <Text styles={{ root: { fontSize: '1.125rem' } }}>Preview</Text>
        </Stack>
      </Stack>
      {userData && (
        <Stack horizontal>
          {(scenario === 'skill_selected' || scenario === 'conversation') && (
          <Stack
            horizontal
            verticalAlign="start">
            <FluentProvider theme={webLightTheme} className={styles.provider}>
              <Button
                className={styles.button}
                shape="circular"
                appearance="primary"
                as="a"
                target="_blank"
                href={selectedSkill.contact_url}
                onClick={() => onContactEPSOSMEClick()}
              >
                Contact EPSO SME
              </Button>
            </FluentProvider>
          </Stack>
          )}
          <Stack
            id="user-btn"
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: '0.81rem' }}
            styles={{
              root: {
                // backgroundColor: 'white',
                marginRight: '2.5rem',
              },
            }}
          >
            <Text styles={{
              root: {
                height: '40px',
                fontSize: '1.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                "@media (max-width: 576px)": {
                  fontSize: '1rem',
                  display:'none'
                }
              }
            }}>
              {userData?.displayName}
            </Text>
            <IconButton
              menuProps={menuProps}
              onRenderMenuIcon={() => null}
              iconProps={{ iconName: 'Contact', style: { fontSize: '2.2rem' } }}
              style={{
                borderRadius: '50%',
                backgroundColor: 'gray',
                color: 'white',
                width: '2.87rem',
                height: '2.87rem',
              }}
              title={userData?.displayName}
              ariaLabel="User"
            >
            </IconButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
