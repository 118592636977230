import { useRouteError, useLocation, isRouteErrorResponse } from 'react-router-dom';
import { Error400Page, Error401Page, Error403Page, Error404Page, Error500Page } from '.';
import { Errors } from '../../components/Errors';
import { APP_TITLE } from '../../constants/env';

export const ErrorPage = () => {
  const error = useRouteError();
  const location = useLocation();

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 400:
        return <Error400Page />;
      case 401:
        return <Error401Page />;
      case 403:
        return <Error403Page />;
      case 404:
        return <Error404Page />;
      case 500:
        return <Error500Page />;
      default:
        return (
          <div>
            <h1>{error.status} Error</h1>
            <p>{error.statusText || ' An unknown error occurred.'}</p>
          </div>
        );
    }
  } else {
    const details = location.state?.errMsg || ' An unknown error occurred.';
    return (
      <Errors appTitle={APP_TITLE}>
        <h4>Error</h4>
        <p>Details: {details}</p>
      </Errors>
    );
  }
};