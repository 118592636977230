import React, { useEffect } from 'react';
import { ApiClient } from './utils/api'
import { ApiClientContext } from './contexts/ApiClient';
import { UserProvider } from './contexts/UserContext';
import { VersionProvider } from './contexts/VersionContext';
import { RouterProvider } from 'react-router-dom';
import routes from './routes/routes';
import { APP_TITLE, API_PREFIX } from './constants/env';

const apiClient = new ApiClient(API_PREFIX);

export const App: React.FunctionComponent = () => {
  useEffect(() => {
    // Set the document title
    document.title = APP_TITLE;
  }, [])
  
  return (
    <ApiClientContext.Provider value={apiClient}>
      <VersionProvider apiClient={apiClient}> 
        <UserProvider>
          <RouterProvider router={routes} />
        </UserProvider>
      </VersionProvider>
    </ApiClientContext.Provider>
  );
};
