import React, { createContext, useContext, useState, ReactNode } from 'react';
import { VersionData, ApiClient } from '../utils/api';

export interface VersionContextType {
    versionData: VersionData | undefined;
    setVersionData: React.Dispatch<React.SetStateAction<VersionData | undefined>>;
  }

export const VersionContext = createContext<VersionContextType | undefined>(undefined);

export const useVersion = () => {
  const context = useContext(VersionContext);
  if (context === undefined) {
    throw new Error('useVersion must be used within a VersionProvider');
  }
  return context;
};

export const VersionProvider: React.FC<{ children: ReactNode, apiClient: ApiClient }> = ({ children, apiClient }) => {
  const [versionData, setVersionData] = useState<VersionData | undefined>(undefined);
  return (
    <VersionContext.Provider value={{ versionData, setVersionData }}>
      {children}
    </VersionContext.Provider>
  );
};