import { Stack, Text, getTheme, SpinnerSize, Spinner } from '@fluentui/react';
import { NavigationBar } from './NavigationBar';
import { Footer } from './Footer';
import { useState } from 'react';
import { ReactComponent as MsaSignIn } from '../assets/ms_signin_light.svg';

// Fluent UI 2 components
import {
  makeStyles,
  Button,
  shorthands,
} from '@fluentui/react-components';

import { useMsal } from "@azure/msal-react";
import { msaLoginRequest } from "../authConfig";
import { BrowserAuthError } from "@azure/msal-browser";
const theme = getTheme();

const stackTokens = {
  smallGap: { childrenGap: '10px' },
  largeGap: { childrenGap: '2rem' },
  mediumStack: { childrenGap: '1rem', padding: '25px 0 0 0' },
};

const useStyles = makeStyles({
  // Temporary fix for the button's background color
  provider: {
    backgroundColor: '#FAFAFA',
  },
  errorText: { 
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('5px'),
    backgroundColor: theme.semanticColors.errorBackground,
  },
  headerText: {
    fontWeight: 600,
    fontSize: '4.5rem',
    lineHeight: 'normal',
  },
  centeredStack: {
    justifyContent: 'center', 
    alignItems: 'center',     
    minHeight: '300px',
    minWidth: '300px',
  },
  horizontalStack: {
    justifyContent: 'center', 
    alignItems: 'center',     
    flexDirection: 'row',
  },
  footer: {
    justifyContent: 'center', 
    alignItems: 'center',     
    marginBottom: '4.37rem',
  },
  signInButton: {
    width: '215px', 
    height: '41px',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  
});
export interface IHomeProps {
  appTitle: string;
}
export const Home = ({ appTitle }: IHomeProps) => {
  const styles = useStyles();
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    try {
      // login using MSAL
      instance.loginRedirect(msaLoginRequest); 
    } catch (err) {
      handleLoginError(err)    
      setLoading(false);
    }
  };
  const handleLoginError = (err: any) => {
    // Handle login failure (window closed)
    if (err instanceof BrowserAuthError) {
      console.error("Login failed:", err);
    } else {
      console.error("Unexpected error while Login:", err);
    }
  };

  return (
    <Stack verticalAlign="center" verticalFill data-testid="home-component">
      <NavigationBar 
        scenario={'wait_select_skill'} 
        onContactEPSOSMEClick={() => void 0}
      />
      <Stack
        className={styles.centeredStack}
        verticalFill
        tokens={stackTokens.smallGap}
      >
        <Stack
          className={styles.horizontalStack}
          tokens={stackTokens.largeGap}
        >
          <Text styles={{ root: styles.headerText }} data-testid="app-title">
            {appTitle}
          </Text>
        </Stack>
        <Stack tokens={stackTokens.mediumStack}>
          <Button className={styles.signInButton}  data-testid="signin-with-microsoft-btn" onClick={handleLogin}>
            {loading ? (
              <Spinner size={SpinnerSize.large}  data-testid="loading-spinner" />
            ) : (
              <MsaSignIn/>
            )}
          </Button>
        </Stack>
               
      </Stack>
      <Stack className={styles.footer}>
        <Footer/>
      </Stack>
    </Stack>
  );
};


