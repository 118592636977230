import React from 'react';
import { Stack, getTheme } from '@fluentui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const theme = getTheme();

export interface IErrorMessageProps {
  details: string;
}

export const ErrorMessage = ({ details }: IErrorMessageProps) => {
  return (
    <Stack
      styles={{
        root: {
          backgroundColor: theme.semanticColors.errorBackground,
          borderRadius: '0.5rem',
          padding: '0.5rem',
          wordBreak: 'normal',
          overflowWrap: 'anywhere',
        },
      }}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        children={details}
      />
    </Stack>
  );
};
