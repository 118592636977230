import { useLocation } from 'react-router-dom';
import { Errors } from '../../components/Errors';
import { APP_TITLE } from '../../constants/env';

export const Error403Page = () => {
  const location = useLocation();
  const details = location.state?.errMsg || 'You don\'t have permission to access this page.';

  return (
    <Errors appTitle={APP_TITLE}  logoutButton={true}>
      <h4>403 - Forbidden</h4>
        You do not have permission to use this bot. Please contact the team
        for access.
      <p>Details: {details}</p>
    </Errors>
  );
};