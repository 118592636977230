import { Stack, Text, Link } from '@fluentui/react';
import { FAQ } from './FAQ';
import { Terms } from './Terms';
import { useState } from 'react';
import { FEEDBACK_URL, PRIVACY_URL } from '../constants/env';

export const Footer = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);
  const handleTermsClick = () => {
    setShowTerms(true);
    setShowFAQ(false);
  };

  const handleFAQClick = () => {
    setShowFAQ(true);
    setShowTerms(false);
  };

  const handleOnClose = () => {
    setShowTerms(false);
    setShowFAQ(false);
  };

  return (
    <Stack>
      <Text styles={{ root: { fontSize: '1.25rem', textAlign: 'center' } }}>
        <FooterLinks fontSize="1.25rem" onTermsClick={handleTermsClick} onFAQClick={handleFAQClick} />
      </Text>
      <Terms open={showTerms} onClose={handleOnClose} />
      <FAQ open={showFAQ} onClose={handleOnClose} />
    </Stack>
  );
};

export interface FooterLinksProps {
  fontSize?: string;
  onTermsClick?: () => void;
  onFAQClick?: () => void;
}

export const FooterLinks = ({ fontSize = '1.25rem', onTermsClick, onFAQClick }: FooterLinksProps) => {
  return (
    <Text styles={{ root: { fontSize: fontSize } }}>
      <Link target="_blank" href={FEEDBACK_URL}>Feedback</Link> |{' '}
      <Link onClick={() => { if (onTermsClick !== undefined) { onTermsClick() } }}>Terms</Link> |{' '}
      <Link target="_blank" href={PRIVACY_URL}>Privacy</Link> |{' '}
      <Link onClick={() => { if (onFAQClick !== undefined) { onFAQClick() } }}>FAQ</Link>
    </Text>
  )
}
