import { useNavigate, useSearchParams } from 'react-router-dom';
import { Stack, Text, Spinner, SpinnerSize } from '@fluentui/react';
import { useContext, useEffect } from 'react';
import { ApiClientContext } from "../../contexts/ApiClient";
import { ApiAuthenticationError, ApiAuthorizationError, ApiBadRequestError, ApiClient, ApiConflictError } from "../../utils/api";
import { useMsal } from '@azure/msal-react';
import { msaLoginRequest } from '../../authConfig';
import { PATH_CHAT, PATH_ERROR, PATH_HOME } from '../../constants/routes';
import { BrowserAuthError } from '@azure/msal-browser';

export const OnboardingPage = () => {
  const [searchParams] = useSearchParams();
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const apiClient = useContext(ApiClientContext) as ApiClient;
  const code = searchParams.get('code');

  useEffect(() => {
    const handleOnboarding = async () => {
      // Ensure MSAL is initialized
      if (inProgress === 'none') { 
        const activeAccount = instance.getActiveAccount();
        if (!activeAccount) {
          // User not logged in, redirect to MSALlogin and put code in state
          try {
            instance.loginRedirect({
              scopes: msaLoginRequest.scopes,
              state: JSON.stringify({ onboardingCode: code }),
              redirectStartPage: PATH_HOME.root
            });
          } catch (err) {
            if (err instanceof BrowserAuthError) {
              console.error("Login failed:", err);
            } else {
              console.error("Unexpected error while Login:", err);
            }
          }
        } else {
          // User already logged in, call onboarding API
          try {
            await apiClient.onboarding(code || '');
            navigate(PATH_CHAT.root, { replace: true });
          } catch (apiError: any) {
            if (apiError instanceof ApiConflictError) {
              // User already onboarded, redirect to chat page
              navigate(PATH_CHAT.root, { replace: true });
            } else if (apiError instanceof ApiAuthenticationError) {
              navigate(PATH_ERROR.error401, { state: { 
                errMsg: 'Not authenticated, please re-onboard.' 
              }});
            } else if (apiError instanceof ApiAuthorizationError) {
              navigate(PATH_ERROR.error403, { state: {
                errMsg:'Your onboarding code is invalid, or you have not been added to the system yet. Please contact the EPSO team for further assistance.' 
              }});
            } else if (apiError instanceof ApiBadRequestError) {
              navigate(PATH_ERROR.error400, { state: { errMsg: apiError.message } });
            } else {
              console.error('Onboarding error:', apiError);
              navigate(PATH_ERROR.error500, { state: { errMsg: 'Error during onboarding: ' + apiError } });
            }
          }
        }
      }
    };

    handleOnboarding();
  }, [code, instance, apiClient, navigate, inProgress]);

  return (
    <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { height: '100vh' } }}>
      <Text variant="xLarge">Onboarding...</Text>
      <Spinner size={SpinnerSize.large} />
    </Stack>
  );
};
