import { AccountInfo } from "@azure/msal-browser";
import { BrowserUtils } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { PATH_HOME } from "../../constants/routes";
import { makeStyles, shorthands, Text } from '@fluentui/react-components';
import { Spinner, SpinnerSize } from '@fluentui/react';
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    ...shorthands.padding('0', '20px'),
  },
  message: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '10px',
  },
});


export const LogoutPage = () => {
  const { instance } = useMsal();
  const styles = useStyles();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    if (!isLoggingOut) {
      setIsLoggingOut(true); 
      instance.logoutRedirect({
        account: instance.getActiveAccount() as AccountInfo,
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
        postLogoutRedirectUri: PATH_HOME.root,
      }).catch(error => {
        console.error("Logout failed:", error);
        setIsLoggingOut(false); 
      });
    }
  }, [instance, isLoggingOut]);
  
  return (
    <div className={styles.container}>
      <Text className={styles.message}>Logging out...</Text>
      <Spinner size={SpinnerSize.large} style={{ marginTop: '20px' }} />
    </div>  
  );
};
