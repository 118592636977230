// Custom error issue when extending built-in error:
// https://github.com/microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
import { msalInstance } from '../index';
import { msaLoginRequest } from '../authConfig';


const DEFAULT_API_TIMEOUT = 60000;
const DEFAULT_CONVERSATIONS_API_TIMEOUT = 60000;
const DEFAULT_STREAMING_API_TIMEOUT = 60000;

// Helper function to timeout streaming
function throwTimeoutAfter(ms: number) {
  return new Promise((_, reject) =>
    setTimeout(() => reject(new TimeoutError(`${ms}ms`)), ms)
  );
}

// Helper function to aggregate multiple abort signals into one.
function anySignal(signals: AbortSignal[]) {
  const controller = new AbortController();

  function onAbort() {
    controller.abort();

    // Cleanup
    for (const signal of signals) {
      signal.removeEventListener('abort', onAbort);
    }
  }

  for (const signal of signals) {
    if (signal.aborted) {
      onAbort();
      break;
    }
    signal.addEventListener('abort', onAbort);
  }

  return controller.signal;
}

export class ApiAuthenticationError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, ApiAuthenticationError.prototype);
  }
}

export class ApiAuthorizationError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, ApiAuthorizationError.prototype);
  }
}

export class ApiBadRequestError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, ApiBadRequestError.prototype);
  }
}
export class ApiConflictError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, ApiConflictError.prototype);
  }
}

export class ApiRateLimitExceededError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, ApiRateLimitExceededError.prototype);
  }
}

export class ApiUnprocessableContentError extends Error {
  public detail: any;

  constructor(m: string, detail: any) {
    super(m);
    Object.setPrototypeOf(this, ApiUnprocessableContentError.prototype);
    this.detail = detail;
  }
}

export class ApiUnexpectedError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, ApiUnexpectedError.prototype);
  }
}
export class TimeoutError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}

export class UserAbortError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, UserAbortError.prototype);
  }
}

export class NoActiveAccountError extends Error {
  constructor() {
    super('No active account found. User must be logged in.');
    Object.setPrototypeOf(this, NoActiveAccountError.prototype);
  }
}

export class TokenAcquisitionError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, TokenAcquisitionError.prototype);
  }
}

export interface AiMessageDebugInfo {
  intention: string | null;
  plan: string | null;
}

export interface Reference {
  id: string | number;
  title: string;
  url: string;
}

export interface Conversation {
  id?: string;
  messages: (SystemMessage | HumanMessage | AiMessage)[];
  selected_skill?: string;
  include_debug_info?: boolean;
}

export interface Message {
  id?: string;
  role: 'human' | 'assistant' | 'system';
  content: string;
}

export interface HumanMessage extends Message {
  role: 'human';
  include_openai_api_info?: boolean;
}

export function isHumanMessage(msg: Message): msg is HumanMessage {
  return msg.role === 'human';
}

export interface AiMessage extends Message {
  role: 'assistant';
  references?: Reference[];
  debug_info?: AiMessageDebugInfo;
}

export function isAiMessage(msg: Message): msg is AiMessage {
  return msg.role === 'assistant';
}

export interface SystemMessage extends Message {
  role: 'system';
}

export function isSystemMessage(msg: Message): msg is SystemMessage {
  return msg.role === 'system';
}

export enum StreamEventType {
    STATUS_UPDATE = 'status_update',
    MESSAGE = 'message',
    ERROR = 'error'
}

export enum StatusUpdateType {
    PLANNING = 'generating_plan',
    ANSWERING = 'generating_answer',
    END = 'end',
    KEEPALIVE = 'ping'
}

export interface StatusUpdateData {
    status: StatusUpdateType;
    details: string;
}

export interface StatusUpdateEvent {
    id: string;
    type: 'status_update';
    timestamp: string;
    data: StatusUpdateData;
}

export interface MessageEventData {
    role: 'assistant' | 'citations' | 'intents';
    content: string | null;
}

export interface MessageEvent {
    id: string;
    type: StreamEventType.MESSAGE;
    timestamp: string;
    data: MessageEventData;
}

export interface ErrorData {
    error: 'skill_execution_error' | 'content_filter' | 'aoai_api_rate_limit_exceeded' | 'unexpected_openai_error';
    details: string;
}

export interface ErrorEvent {
    id: string;
    type: StreamEventType.ERROR;
    timestamp: string;
    data: ErrorData;
}

export type StreamEvent = StatusUpdateEvent | MessageEvent | ErrorEvent;

export function parseStreamEvent(event: any): StreamEvent | null {
  try {
    switch (event.type) {
      case StreamEventType.STATUS_UPDATE:
        if (!event.data?.status) return null;
        return event as StatusUpdateEvent;
      case StreamEventType.MESSAGE:
        if (event.data?.role !== 'assistant' && event.data?.role !== 'citations' && event.data?.role !== 'intents') return null;
        return event as MessageEvent;
      case StreamEventType.ERROR:
        if (!event.data?.error) return null;
        return event as ErrorEvent;
      default:
        console.error('Invalid event');
        return null;
    }
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
}

export interface CommentData {
  id: string;
  user_id: string;
  answer_id: string;
  comment: string;
  question: string | undefined;
  answer: string;
  is_like: boolean;
  dislike_reason: string;
  version: string;
  references: Reference[];
  relatedDocuments: Reference[];
  conversation: Conversation;
  human_reachout: boolean;
  selected_skill: string,
}

export interface UserInfo {
  id: string;
  displayName: string;
  roles: string[];
}

export interface RequestInitWithTimeout extends RequestInit {
  timeout?: number;
}

export interface VersionData {
  version: string;
}

export interface SkillData {
  id: string;
  display_name: string;
  description: string;
  selectable: boolean;
  sample_questions: string[];
  contact_url: string;
}

async function acquireTokenSilent(): Promise<string> {
  const activeAccount = msalInstance.getActiveAccount();
  if (!activeAccount) {
    throw new NoActiveAccountError();
  }

  const response = await msalInstance.acquireTokenSilent({
    ...msaLoginRequest,
    account: activeAccount,
  });

  if (!response || !response.accessToken) {
    throw new TokenAcquisitionError('Silent token acquisition failed, no access token received');
  }

  return response.accessToken;
}

async function acquireTokenInteractive(): Promise<string> {
  const activeAccount = msalInstance.getActiveAccount();
  if (!activeAccount) {
    throw new NoActiveAccountError();
  }

  const response = await msalInstance.acquireTokenPopup({
    ...msaLoginRequest,
    account: activeAccount,
  });

  if (!response || !response.accessToken) {
    throw new TokenAcquisitionError('Interactive token acquisition failed, no access token received');
  }

  return response.accessToken;
}

export async function getAccessToken(): Promise<string> {
  try {
    return await acquireTokenSilent();
  } catch (silentError) {
    if (silentError instanceof NoActiveAccountError) {
      throw silentError;
    }
    console.log('Silent token acquisition failed, will use interactive method to acquire token', silentError);
    try {
      return await acquireTokenInteractive();
    } catch (interactiveError) {
      if (interactiveError instanceof NoActiveAccountError) {
        throw interactiveError;
      }
      console.error('Interactive token acquisition failed', interactiveError);
      throw new TokenAcquisitionError('Interactive token acquisition failed, no access token received');
    }
  }
}


export async function fetchWithTimeout(
  url: string,
  options: RequestInitWithTimeout = {}
): Promise<any> {
  const timeout = options.timeout || DEFAULT_API_TIMEOUT;
  delete options.timeout;

  const timeoutController = new AbortController();
  const id = setTimeout(() => timeoutController.abort(), timeout);
  const userAbortSignal = options.signal;

  if (userAbortSignal) {
    options.signal = anySignal([userAbortSignal, timeoutController.signal]);
  } else {
    options.signal = timeoutController.signal;
  }
  // Fetch the Token and add it to the Authorization header of the request
  let accessToken = await getAccessToken();

  const headers = new Headers(options.headers);
  headers.append('Authorization', `Bearer ${accessToken}`);
  options.headers = headers;

  let response;
  // Handle abort, timeout, or fetch failed errors
  try {
    response = await fetch(url, options);
    clearTimeout(id);
  } catch (err: any) {
    if (err?.name === 'AbortError') {
      if (userAbortSignal?.aborted) {
        throw new UserAbortError('User aborted');
      } else if (timeoutController.signal.aborted) {
        throw new TimeoutError(`${timeout}ms`);
      }
    }
    // Rethrow other errors
    throw err;
  }
  // Got API response. Handle API response errors
  if (!response.ok) {
    switch (response.status) {
      case 400:
        throw new ApiBadRequestError('Bad request');
      case 401:
        throw new ApiAuthenticationError('Unauthenticated: 401');
      case 403:
        throw new ApiAuthorizationError('No permission');
      case 409:
        throw new ApiConflictError('Conflict');
      case 422:
        const respObj = await response.json();
        throw new ApiUnprocessableContentError('Invalid input', respObj.detail);
      case 429:
        throw new ApiRateLimitExceededError('Rate limit exceeded');
      default:
        const body = await response.text();
        throw new ApiUnexpectedError(
          `Unexpted error: HTTP ${response.status}: ${body}`
        );
    }
  }

  return response;
}

export class JsonStreamParser {
  /* Parsing a stream consists of JSON separated by single newline '\n' */
  private buffer: string = '';

  constructur() {}

  append(streamData: string) {
    /* Append stream data to the buffer */
    this.buffer += streamData;
  }

  parse(): [object[], string] {
    /* Parse the buffer and return an array of parsed JSON objects and the remaining buffer */
    const jsonTexts = this.buffer.split('\n');

    // Only the last item has the possibility of being incomplete.
    // But if it's complete, it will be an empty string so we can safely pop it out and ignore it.
    const lastJsonText = jsonTexts.pop();
    this.buffer = lastJsonText || '';

    const parsedObjs = jsonTexts.map(jsonText => {
      try {
        return JSON.parse(jsonText);
      } catch (e) {
        console.warn('Skip error JSON:', jsonText, 'Error:', e);
        return null;
      }
    }).filter(json => json !== null)

    return ([parsedObjs, this.buffer]);
  }
}

export class ApiClient {
  apiPrefix: string;

  constructor(apiPrefix: string = '/api') {
    if (!apiPrefix.startsWith('/')) {
      apiPrefix = '/' + apiPrefix;
    }
    if (apiPrefix.endsWith('/')) {
      apiPrefix = apiPrefix.slice(0, -1);
    }
    this.apiPrefix = apiPrefix;
  }

  public async newConversation(
    conv: Conversation,
    signal?: AbortSignal,
    includeDebugInfo: boolean = true
  ): Promise<any> {
    const url = `${this.apiPrefix}/conversations/`;
    conv.include_debug_info = includeDebugInfo;

    const response = await fetchWithTimeout(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(conv),
      signal: signal,
      timeout: DEFAULT_CONVERSATIONS_API_TIMEOUT,
    });

    return (await response.json()) as Conversation;
  }

  public async *newConversationStream(
    conv: Conversation,
    signal?: AbortSignal
  ): AsyncGenerator<StreamEvent, void, unknown> {
    const url = `${this.apiPrefix}/conversations/`;
    const response = await fetchWithTimeout(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(conv),
      signal: signal,
      timeout: DEFAULT_STREAMING_API_TIMEOUT,
    });

    if (response?.body) {
      const reader = response.body.getReader();
      const jsonStreamParser = new JsonStreamParser();
      const utf8Decoder = new TextDecoder('utf-8');

      while (true) {
        if (signal?.aborted) {
          throw new UserAbortError('User aborted streaming');
        }

        try {
          // Race the reader.read() against a timeout
          const { done, value } = await Promise.race([
            reader.read(),
            throwTimeoutAfter(DEFAULT_STREAMING_API_TIMEOUT),
          ]);
          if (done) break;

          jsonStreamParser.append(utf8Decoder.decode(value, {stream: true}));
          const [objects, remainingText] = jsonStreamParser.parse();
          for (var i = 0; i < objects.length; i++) {
            const evt = parseStreamEvent(objects[i]);
            if (evt) {
              yield evt;
            } else {
              console.log('Warning: Unable to parse object into event:', objects[i]);
            }
          }

          if (remainingText) console.log('Pending JSON text:', remainingText);
        } catch (err: any) {
          // Throw abort as UserAbortError and rethrow other errors
          if (err?.name === 'AbortError') {
            throw new UserAbortError('User aborted streaming');
          } else {
            throw err;
          }
        }
      }
    }
  }

  public async getApiHealth(): Promise<boolean> {
    const response = await fetchWithTimeout(`${this.apiPrefix}/health/`);
    return response.ok;
  }

  public async getApiVersion(): Promise<VersionData | undefined> {
    const response = await fetchWithTimeout(`${this.apiPrefix}/version/`);

    if (response.ok) {
      return (await response.json()) as VersionData;
    } else {
      return undefined;
    }
  }

  public async getUserInfo(): Promise<UserInfo> {
    const response = await fetchWithTimeout('/api/me/');

    // If user is not logged in, this endpoint will return 200 and an empty clientPrincipal
    if (response.ok) {
      const resp = (await response.json());
      if (!resp) {
        throw new ApiAuthenticationError(
          'Unauthenticated: API responded empty user info'
        );
      }

      if (!resp.id || !resp.email || !resp.roles) {
        throw new ApiAuthenticationError(
          'Unauthenticated: API responded incomplete user info'
        );
      }
      return {
        id: resp.id,
        displayName: resp.email,
        roles: resp.roles,
      };
    } else {
      throw new Error(
        'THIS IS IMPOSSIBLE. Error should be caught by fetchWithTimeout'
      );
    }
  }

  public async sendComment(comment: CommentData): Promise<CommentData> {
    const url = `${this.apiPrefix}/comments/`;

    const response = await fetchWithTimeout(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(comment),
    });

    return (await response.json()) as CommentData;
  }

  public async getAvailableSkills() {
    const response = await fetchWithTimeout(`${this.apiPrefix}/skills/`);
    return (await response.json()) as SkillData[];
  }
  
  public async onboarding(code: string): Promise<void> {
    const url = `${this.apiPrefix}/user/onboarding/`;
    await fetchWithTimeout(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
      timeout: DEFAULT_API_TIMEOUT,
    });
    return;
  }
}
