import {
    FluentProvider,
    webLightTheme,
    makeStyles,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    Button,
  } from "@fluentui/react-components";
  import { useState, useEffect } from "react";
  import { Dismiss24Regular } from "@fluentui/react-icons";

  const useStyles = makeStyles({
    termsDialog: {
      maxWidth: '85%',
    },
    termsSubTitle: {
        marginTop: '0',
        fontSize: '0.9rem',
        fontWeights: '400',
        lineHeight: '1.25rem',
    },
    termsContent: {
        fontSize: '0.9rem',
        fontWeights: '400',
        lineHeight: '1.25rem',
    },
  });

  export interface ITermsProps {
    open: boolean;
    onClose?: () => void;
  }
  
  export const Terms = ({ open, onClose }: ITermsProps) => {
    const styles = useStyles();
    const [openState, setOpenState] = useState(false);

    useEffect(() => {
      setOpenState(open);
    }, [open]);

    const closeDialog = () => {
      setOpenState(false);
      if (onClose) {
        onClose();
      }
    };

    return (
      <FluentProvider theme={webLightTheme}>
        <Dialog modalType="modal" open={openState}>
          <DialogSurface className={styles.termsDialog}>
            <DialogBody>
              <DialogTitle
                action={
                  <DialogTrigger action="close">
                    <Button
                      appearance="subtle"
                      aria-label="close"
                      icon={<Dismiss24Regular color="#174AE4" />}
                      onClick={() => closeDialog()}
                    />
                  </DialogTrigger>
                }
              >
                MICROSOFT PREVIEW TERMS FOR PROJECT EDGEFIELD
              </DialogTitle>
              <DialogContent>
                <p className={styles.termsSubTitle}>
                  Purpose – Validate system functionality and evaluate system
                  response quality
                </p>
                <hr />
                <p className={styles.termsContent}>
                  These terms are an agreement between you and Microsoft
                  Corporation and they apply to your use of Project Edgefield,
                  its content and associated software (together “Project
                  Edgefield”). IF YOU COMPLY WITH THESE TERMS, YOU HAVE THE
                  RIGHTS BELOW. BY ACCESSING OR USING THE PROJECT EDGEFIELD, YOU
                  ACCEPT THESE TERMS.
                </p>
                <ol className={styles.termsContent}>
                  <li>
                    <strong>USE RIGHTS.</strong> You may access and use Project
                    Edgefield to review, evaluate and test Project Edgefield
                    (e.g., posting technical questions and reviewing the
                    responses to those questions) for the purpose stated above.
                    Project Edgefield may include third party components with
                    separate legal notices or governed by other agreements, as
                    described in the Third Party Notices file.
                  </li>
                  <li>
                    <strong>LIMITED PREVIEW PERIOD.</strong> This agreement is
                    effective on your acceptance and terminates on the earlier
                    of 30 days following the first availability of a commercial
                    release of Project Edgefield or upon termination by
                    Microsoft. You may not be able to access the content and
                    data used in Project Edgefield when it stops running.
                  </li>
                  <li>
                    <strong>RESERVATION OF RIGHTS.</strong> Except for the
                    licenses granted to you in Section 1, Microsoft reserves all
                    rights in Project Edgefield.
                  </li>
                  <li>
                    <strong>PRE-RELEASE.</strong> Project Edgefield is a
                    pre-release version. It may not operate correctly and may be
                    substantially different from the commercially released
                    version. Microsoft may not release a commercial version.
                  </li>
                  <li>
                    <strong>FEEDBACK.</strong>
                    <ol type="a">
                      <li>
                        Providing “Feedback” (suggestions, comments, feedback,
                        ideas, or know-how, in any form) to Microsoft about
                        Project Edgefield is voluntary. Microsoft is under no
                        obligation to post or use any Feedback. By providing
                        Feedback to Microsoft, you irrevocably and perpetually
                        grant to Microsoft and its affiliates, under all of your
                        owned or controlled intellectual property rights, a
                        worldwide, non-exclusive, fully paid-up, royalty-free,
                        transferable, sub-licensable right and license to make,
                        use, reproduce, prepare derivative works based upon,
                        distribute, publicly perform, publicly display,
                        transmit, and otherwise commercialize the Feedback
                        (including by combining or interfacing products,
                        services or technologies that depend on or incorporate
                        Feedback with other products, services or technologies
                        of Microsoft or others), without attribution in any way
                        and for any purpose.
                      </li>
                      <li>
                        You warrant that 1) you will not provide Feedback that
                        is subject to a license requiring Microsoft to license
                        anything to third parties because Microsoft exercises
                        any of the above rights in your feedback and 2) you own
                        or otherwise control all of the rights to such Feedback
                        and that no Feedback is subject to any third-party
                        rights (including any personality or publicity rights).
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>DATA.</strong>
                    <ol type="a">
                      <li>
                        <strong>Data Collection.</strong> Project Edgefield may
                        collect information about you and your use of Project
                        Edgefield, and send that to Microsoft. Microsoft may use
                        this information to provide services and improve our
                        products and services. You can learn more about data
                        collection and use in the documentation and the privacy
                        statement at{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://aka.ms/privacy"
                        >
                          https://aka.ms/privacy
                        </a>
                        . Your use of Project Edgefield operates as your consent
                        to these practices.
                      </li>
                      <li>
                        <strong>Processing of Personal Data.</strong> To the
                        extent Microsoft is a processor or subprocessor of
                        personal data in connection with Project Edgefield,
                        Microsoft makes the commitments in the European Union
                        General Data Protection Regulation Terms of the Online
                        Services Terms to all customers effective May 25, 2018,
                        at{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://docs.microsoft.com/en-us/legal/gdpr"
                        >
                          https://docs.microsoft.com/en-us/legal/gdpr
                        </a>
                        .
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>TRADE COMPLIANCE.</strong> You must comply with all
                    relevant trade laws and regulations that apply to Project
                    Edgefield including, but not limited to, the U.S. Export
                    Administration Regulations or other end-user, end use, and
                    destination restrictions by the U.S. and other governments,
                    as well as sanctions regulations administered by the U.S.
                    and other governments.  For further information on export
                    restrictions, visit (aka.ms/exporting).
                  </li>
                  <li>
                    <strong>SUPPORT SERVICES.</strong> Microsoft is not
                    obligated under this agreement to provide any support
                    services for Project Edgefield. Any support provided is “as
                    is”, “with all faults”, and without warranty of any kind.
                  </li>
                  <li>
                    <strong>UPDATES.</strong> Project Edgefield may periodically
                    check for updates, and download and install them for you.
                    You may obtain updates only from Microsoft or authorized
                    sources. Microsoft may need to update your system to provide
                    you with updates. You agree to receive these automatic
                    updates without any additional notice.
                  </li>
                  <li>
                    <strong>GOVERNING LAW.</strong> The laws of the State of
                    Washington govern this agreement. If federal jurisdiction
                    exists, the parties consent to exclusive jurisdiction and
                    venue in the federal courts in King County, Washington. If
                    not, the parties consent to exclusive jurisdiction and venue
                    in the Superior Court of King County, Washington, USA.
                  </li>
                  <li>
                    <strong>DISCLAIMER OF WARRANTY.</strong> PROJECT EDGEFIELD
                    AND ALL DATA AND CONTENT CONTAINED THEREIN ARE PROVIDED “AS
                    IS” AND “WITH ALL FAULTS”. YOU BEAR THE RISK OF USING THEM.
                    MICROSOFT GIVES NO EXPRESS WARRANTIES, GUARANTEES, OR
                    CONDITIONS. TO THE EXTENT PERMITTED UNDER APPLICABLE LAWS,
                    MICROSOFT EXCLUDES ALL IMPLIED WARRANTIES, INCLUDING
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                    NON-INFRINGEMENT.
                  </li>
                  <li>
                    <strong>LIMITATION OF LIABILITY.</strong> IN NO EVENT SHALL
                    EITHER PARTY BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT,
                    INCIDENTAL, PUNITIVE, SPECIAL OR OTHER DAMAGES WHATSOEVER,
                    INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS
                    PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS
                    INFORMATION, AND THE LIKE, ARISING OUT OF THIS AGREEMENT OR
                    THE USE OF OR INABILITY TO USE PROJECT EDGEFIELD, EVEN IF
                    SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES.
                  </li>
                  <li>
                    <strong>CONFIDENTIAL INFORMATION.</strong> Project
                    Edgefield, including its content, output, user interface,
                    features and documentation, is confidential and proprietary
                    to Microsoft and its suppliers.
                    <ol type="a">
                      <li>
                        <strong>Use.</strong> For five years after the first
                        instance of your Project Edgefield access or its
                        commercial release, whichever is first, you may not
                        disclose confidential information to third parties. You
                        may disclose confidential information only to your
                        employees and consultants who need to know the
                        information. You must have written agreements with them
                        that protect the confidential information at least as
                        much as this agreement.
                      </li>
                      <li>
                        <strong>Survival.</strong> Your duty to protect
                        confidential information survives this agreement.
                      </li>
                      <li>
                        <strong>Exclusions.</strong> You may disclose
                        confidential information in response to a judicial or
                        governmental order. You must first give written notice
                        to Microsoft to allow it to seek a protective order or
                        otherwise protect the information. Confidential
                        information does not include information that:
                        <ol type="i">
                          <li>
                            becomes publicly known through no wrongful act;
                          </li>
                          <li>
                            you received from a third party who did not breach
                            confidentiality obligations to Microsoft or its
                            suppliers; or
                          </li>
                          <li>you developed independently.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </FluentProvider>
    );
  };