
// Configuration from environment variables

// The title of the application
export const APP_TITLE: string = process.env.REACT_APP_TITLE || 'Project Edgefield';

// The prefix for the API endpoints
export const API_PREFIX: string = process.env.REACT_APP_API_PREFIX || '/api';

// Your Azure application client ID for authentication
export const MSAL_CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID || '';

// The authority URL for MSAL
export const MSAL_AUTHORITY: string = process.env.REACT_APP_AUTHORITY || 'https://login.microsoftonline.com/common';

// The redirect URI for MSAL
export const MSAL_REDIRECT_URI: string = process.env.REACT_APP_REDIRECT_URI || window.location.origin

// The scope for MSAL
export const MSAL_SCOPES: string[] = process.env.REACT_APP_SCOPES
  ? process.env.REACT_APP_SCOPES.split(',').map(scope => scope.trim())
  : [];
// A boolean value to determine if the reference include ID
export const REFERENCE_INCLUDE_ID: boolean = (process.env.REACT_APP_REFERENCE_INCLUDE_ID || 'true').toLowerCase() === 'true';

// The URL for the feedback form
export const FEEDBACK_URL: string = process.env.REACT_APP_FEEDBACK_URL || 'https://forms.office.com/r/7nAUSP8pmd';

// The URL for the privacy statement
export const PRIVACY_URL: string = process.env.REACT_APP_PRIVACY_URL || 'https://privacy.microsoft.com/en-us/privacystatement';

// The limit for the input text length
export const INPUT_LIMIT: number = process.env.REACT_APP_INPUT_LIMIT ? parseInt(process.env.REACT_APP_INPUT_LIMIT) : 2000;