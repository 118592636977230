import { useLocation } from "react-router-dom";
import { APP_TITLE } from "../../constants/env";
import { Errors } from "../../components/Errors";

export const Error404Page = () => {
  const location = useLocation();
  const details = location.state?.errMsg || ' The page you are looking for does not exist.';

  return (
    <Errors appTitle={APP_TITLE}>
      <h4>404 - Page Not Found</h4>
      <p>Details: {details}</p>
    </Errors>
  );
};
