import { useLocation } from "react-router-dom";
import { Errors } from "../../components/Errors";
import { APP_TITLE } from "../../constants/env";

export const Error400Page = () => {
  const location = useLocation();
  const details = location.state?.errMsg || ' An unexpected error occurred.';
 
  return (
    <Errors appTitle={APP_TITLE}>
      <h4>400 - Bad Request</h4>
      Cannot communicate with the service. Please try again later.
      <p>Details: {details}</p>
    </Errors>
  );
};
