import { createBrowserRouter } from 'react-router-dom';
import {
  Error400Page,
  Error401Page,
  Error403Page,
  Error404Page,
  Error500Page,
  ErrorPage,
  HomePage,
  ChatPage,
  OnboardingPage,
  LogoutPage,
} from '../pages';


import ProtectedRoute from './ProtectedRoute';

// Create the router
const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/onboarding',
    element: <OnboardingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/chat',
    element: <ProtectedRoute children={ <ChatPage  />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/auth',
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'logout',
        element: <LogoutPage />,
      },
    ],
  },
  {
    path: 'errors',
    errorElement: <ErrorPage />,
    children: [
      {
        path: '400',
        element: <Error400Page />,
      },
      {
        path: '401',
        element: <Error401Page />,
      },
      {
        path: '403',
        element: <Error403Page />,
      },
      {
        path: '404',
        element: <Error404Page />,
      },
      {
        path: '500',
        element: <Error500Page />,
      },
      {
        path: '*',
        element: <Error404Page />,
      },
    ],
  },
]);

export default router;