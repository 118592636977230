import { useLocation } from "react-router-dom";
import { Errors } from "../../components/Errors";
import { APP_TITLE } from "../../constants/env";

export const Error401Page = () => {
  const location = useLocation();
  const details = location.state?.errMsg || ' You are not authorized to access this page.';

  return (
    <Errors appTitle={APP_TITLE}>
      <h4>401 - Unauthorized</h4>
      <p>Details: {details}</p>
    </Errors>
    
  );
};
    