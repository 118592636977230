import { useLocation } from "react-router-dom";
import { APP_TITLE } from "../../constants/env";
import { Errors } from "../../components/Errors";

export const Error500Page = () => {
  const location = useLocation();
  const details = location.state?.errMsg || ' Something went wrong on our end. Please try again later.';

  return (
    <Errors appTitle={APP_TITLE}>
      <h4>500 - Internal Server Error</h4>
      <p>Details:{details}</p>
    </Errors>
  );
};
