import React from 'react';
import { Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { PATH_HOME } from '../constants/routes';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { instance } = useMsal();
  const isAuthenticated = instance.getActiveAccount()
  if (!isAuthenticated) {
    return <Navigate to={PATH_HOME.root} replace />;
  }

  return children;
};

export default ProtectedRoute;