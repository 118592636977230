import React, { createContext, useContext, useState, ReactNode } from 'react';
import { UserInfo } from '../utils/api';
export interface UserContextType {
  userData: UserInfo | undefined;
  setUserData: React.Dispatch<React.SetStateAction<UserInfo | undefined>>;
}
// Create a context with a default undefined value
export const UserContext = createContext<UserContextType | undefined>(undefined);

// Define a Hook to access the UserContext
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
      throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};


export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserInfo | undefined>(undefined);
  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
};
