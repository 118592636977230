
import { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { Stack, Text } from '@fluentui/react';
import { SimpleModal } from './SimpleModal';
import { v4 as uuidv4 } from 'uuid';
import {
  ApiClient,
  Conversation,
  HumanMessage,
  Reference,
  AiMessage,
  ApiAuthenticationError,
  ApiAuthorizationError,
  ApiRateLimitExceededError,
  ApiUnprocessableContentError,
  TimeoutError,
  UserAbortError,
  CommentData,
  SkillData,
  StreamEvent,
  StreamEventType,
  StatusUpdateType,
} from '../utils/api';
import { UserInput } from './Input';
import { MessageContainer, SampleQuestionsContainer } from './Container';
import { IMessageProps } from './Message';
import { ApiClientContext } from '../contexts/ApiClient';
import { useVersion } from '../contexts/VersionContext';
import { SelectedSkillContext } from '../contexts/SelectedSkillContext';
import { SkillContainer } from './SkillContainer';
import { NavigationBar } from './NavigationBar';
import { Footer } from './Footer';
import { CommentModal } from './CommentModal';
import { ErrorMessage } from './ErrorMessage';
import {
  FluentProvider,
  webLightTheme,
  makeStyles,
  Button,
} from '@fluentui/react-components';
import { CaretLeft24Regular, CaretRight24Regular } from '@fluentui/react-icons';
import useSize from "../utils/useSize";
import { REFERENCE_INCLUDE_ID } from '../constants/env';
import { useNavigate } from 'react-router-dom';
import { PATH_ERROR } from '../constants/routes';
import { APP_TITLE } from '../constants/env';

const useStyles = makeStyles({
  sampleQuestionsArea: {
    backgroundColor: '#FAFAFA',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexGrow: '1',
    flexShrink: '1',
    flexBasis: '0',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '2.44rem',
  },
  button: {
    backgroundColor: '#174AE4',
    width: '14rem',
    height: '3.125rem',
    paddingLeft: '2.62rem',
    paddingRight: '2.62rem',
    paddingTop: '0.81rem',
    paddingBottom: '0.81rem',
    fontSize: '1rem',
    marginTop: '1.5rem',
    marginLeft: 'calc(((100% - 3.69rem)/2) - 7rem)',
  },
});

export function messagesToConversation(
  messages: IMessageProps[],
  conversationId: string
): Conversation {
  const convMsgs = messages.reduce<(HumanMessage | AiMessage)[]>((acc, msg) => {
    switch (msg.msgObj?.role) {
      case 'human':
        acc.push(msg.msgObj as HumanMessage);
        break;
      case 'assistant':
        acc.push(msg.msgObj as AiMessage);
        break;
      default:
        console.log(
          `Unrecognized message role: ${msg.msgObj?.role}. Skip conversion.`
        );
    }
    return acc;
  }, []);

  return { id: conversationId, messages: convMsgs };
}

export const ChatApp = () => {
  type UIState = 'wait_select_skill' | 'skill_selected' | 'conversation';
  const windowsize = useSize();
  const styles = useStyles();
  const [messages, setMessages] = useState<IMessageProps[]>([]);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [showComment, setShowComment] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showClearModal, setShowClearModal] = useState(false);
  const [commentData, setCommentData] = useState<CommentData>({
    id: '',
    user_id: '',
    answer_id: '',
    comment: '',
    question: '',
    answer: '',
    version: '',
    is_like: true,
    dislike_reason: '',
    references: [],
    relatedDocuments: [],
    conversation: {} as Conversation,
    human_reachout: false,
    selected_skill: '',
  });
  const [skillData, setSkillData] = useState<SkillData[]>([]);
  const [selectedSkillIndex, setSelectedSkillIndex] = useState(-1);
  const [tempSelectedSkillIndex, setTempSelectedSkillIndex] = useState(-1);
  const [scenarioShow, setScenarioShow] = useState<UIState>('wait_select_skill');

  // Use useRef to for these values since changes to these values should not trigger re-render
  const chatId = useRef<string>('');
  const abortController = useRef<AbortController | null>(null);
  // Need to use ref to store the value of tempSelectedSkillIndex since the value is used in a callback
  const tempSelectedSkillIndexRef = useRef(tempSelectedSkillIndex);

  // Context values
  const apiClient = useContext(ApiClientContext) as ApiClient;
  const { versionData } = useVersion();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAvailableSkills = async () => {
      try {
        let skillDataArray = await apiClient.getAvailableSkills();
        let filteredSkillDataArray = processSkillArray(skillDataArray);
        setSkillData(filteredSkillDataArray);
        if (filteredSkillDataArray.length > 1) {
          setScenarioShow('wait_select_skill');
        } else {
          setScenarioShow('skill_selected');
        }
      } catch (error) {
        // It's better to handle navigate and Call API in the page folder not here
        if (error instanceof ApiAuthenticationError) {
          navigate(PATH_ERROR.error401, { 
            state: { errMsg: error.message } 
          });
        } else if (error instanceof ApiAuthorizationError) {
          navigate(PATH_ERROR.error403, { 
            state: { errMsg: error.message } 
          });
        } else {
          console.error('Error fetching available skills:', error);
          navigate(PATH_ERROR.error500, { 
            state: { errMsg: 'Error fetching available skills: ' + error } 
          });
        }
      }
    };

    fetchAvailableSkills();
  }, [apiClient, navigate]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (messages.length !== 0) {
        event.preventDefault();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [messages]);

  useEffect(() => {
    tempSelectedSkillIndexRef.current = tempSelectedSkillIndex;
  }, [tempSelectedSkillIndex]);

  const processSkillArray = (dataArray: SkillData[]) => {
    let filteredArray: SkillData[] = [];
    dataArray.forEach((element, _) => {
      if (element.selectable === true) {
        filteredArray.push(element);
      }
    });

    if (filteredArray.length === 1) {
      setSelectedSkillIndex(0);
    }

    return filteredArray;
  };

  const switchSkillSelect = (index: number) => {
    setSelectedSkillIndex(index);
    if (scenarioShow === 'wait_select_skill') {
      setScenarioShow('skill_selected');
    }
    handleClearChat();
    setShowModal(false);
  };

  const showSwitchSkillModal = (index: number) => {
    if (selectedSkillIndex !== index) {
      setTempSelectedSkillIndex(index);
      setShowModal(true);
    }
  };

  const handleSwitchSkillSubmit = useCallback(() => {
    setSelectedSkillIndex(tempSelectedSkillIndexRef.current);
    setShowModal(false);
    handleClearChat();
  }, []);


  const handleInput = async (inputText: string) => {
    const msgId: string = uuidv4();
    const humanMsg: HumanMessage = {
      id: msgId,
      role: 'human',
      content: inputText,
    };
    const newMessage: IMessageProps = {
      msgObj: humanMsg,
      referenceIncludeId: false,
      corrQuestion: '',
    };

    if (scenarioShow === 'skill_selected') {
      setScenarioShow('conversation');
    }

    if (!chatId.current) {
      chatId.current = uuidv4();
    }

    const newMessagesState = [...messages, newMessage];
    const convObj = messagesToConversation(newMessagesState, chatId.current);
    setMessages(newMessagesState);
    setInputDisabled(true);
    setErrMsg(null);
    const streamingComplete = await handleAiStream(convObj, inputText);
    // Disable the input if streaming failed. Error message should notify users to refresh the session
    setInputDisabled(streamingComplete ? false : true);
  };

  const handleAiStream = async (convObj: Conversation, inputText: string): Promise<boolean> => {
    /* The returned boolean indicates whether the stream was successful or not. */
    let lastEvent = null;
    convObj.selected_skill = skillData[selectedSkillIndex].id;
    try {
      abortController.current = new AbortController();
      const stream = apiClient.newConversationStream(
        convObj,
        abortController.current.signal
      );
      let streamOk = true;
      for await (const evt of stream) {
        console.debug(evt);
        // Once streamOk becomes false, it should stay false
        streamOk = streamOk && handleStreamEvent(evt, inputText);
        lastEvent = evt;
      }
      return streamOk;
    } catch (err) {
      if (err instanceof UserAbortError) {
        console.log('User aborted API call.');
        // User aborted, so should return true!
        return true;
      } else if (err instanceof ApiAuthenticationError) {
        handleError(
          'Your session has expired. To begin a new session, please refresh the page. Please note that your chat history will not carry over. Make sure to copy any important responses before refreshing.'
        );
      } else if (err instanceof ApiAuthorizationError) {
        handleError(
          'You are not authorized to use this bot. Please re-login to the system.'
        );
      } else if (err instanceof ApiRateLimitExceededError) {
        handleError('Rate limit exceeded :( Please try again later.');
      } else if (err instanceof ApiUnprocessableContentError) {
        let invalidErrMsg = 'Invalid input: ';
        if (err.detail.length === 0) {
          invalidErrMsg += err.message;
        } else if (err.detail.length === 1) {
          invalidErrMsg += err.detail[0].msg;
        } else {
          const detailMsgs = err.detail
            .map((e: any) => `- ${e.msg}`)
            .join('\n');
          invalidErrMsg += `\n${detailMsgs}`;
        }
        // Remove the last message since it's the one that triggered the error
        handleError(invalidErrMsg, true);
      } else if (err instanceof TimeoutError) {
        handleError(`Backend request timeout: ${err.message}`);
      } else {
        const unexpectedError = err as Error;
        console.log(err);
        handleError(`Unexpected error: ${unexpectedError.message}`);
      }
      return false;
    } finally {
      if (lastEvent) {
        if (
          !(
            lastEvent.type === 'status_update' &&
            lastEvent.data.status === 'end'
          )
        ) {
          console.warn(
            'Event streaming stopped unexpectedly. Possibly losing some messages.'
          );
        }
        createOrUpdateLastAiMessage({
          msgId: lastEvent.id,
          corrQuestion: inputText,
          loading: false,
        });
      }
    }
  };

  const handleStreamEvent = (evt: StreamEvent, corrQuestion: string): boolean => {
    let isEvtOk = true;
    switch (evt.type) {
      case StreamEventType.STATUS_UPDATE:
        switch (evt.data.status) {
          case StatusUpdateType.PLANNING:
            // First event, add corrQeustion
            createOrUpdateLastAiMessage({
              msgId: evt.id,
              corrQuestion: corrQuestion,
              status: 'Determining what to do',
              loading: true,
            });
            break;
          case StatusUpdateType.ANSWERING:
            createOrUpdateLastAiMessage({
              msgId: evt.id,
              status: `Generating answers using skill "${evt.data.details}"`,
              loading: true,
            });
            break;
        }
        break;
      case StreamEventType.MESSAGE:
        switch (evt.data.role) {
          case 'assistant':
            createOrUpdateLastAiMessage({
              msgId: evt.id,
              content: evt.data.content || '',
              loading: true,
            });
            break;
          case 'citations':
            createOrUpdateLastAiMessage({
              msgId: evt.id,
              references: JSON.parse(evt.data.content || '[]'),
              loading: true,
            });
            break;
        }
        break;
      case StreamEventType.ERROR:
        let errMsg = '';
        switch (evt.data.error) {
          case 'aoai_api_rate_limit_exceeded':
            // If it's OpenAI rate limit exceeded, show a different message
            errMsg = 'Our server is currently under heavy load. Please try again later.'
            break;
          default:
            errMsg = evt.data.details
        }
        handleError(
          `Encountered error while receiving answer: ${errMsg}`
        );
        isEvtOk = false
        break;
      default:
        handleError(`Unknown stream event type: ${evt.type}`);
        isEvtOk = false
    }
    return isEvtOk
  };

  const createOrUpdateLastAiMessage = ({
    msgId,
    corrQuestion,
    status,
    content,
    references,
    loading,
  }: {
    msgId: string;
    corrQuestion?: string;
    status?: string;
    content?: string;
    references?: Reference[];
    loading?: boolean;
  }) => {
    setMessages((msgs) => {
      if (msgs.length === 0) return [];

      if (msgs[msgs.length - 1].msgObj?.id !== msgId) {
        const newMessage: IMessageProps = {
          msgObj: {
            id: msgId,
            role: 'assistant',
            content: content !== undefined ? content : '',
            references: references,
          } as AiMessage,
          referenceIncludeId: REFERENCE_INCLUDE_ID,
          corrQuestion: corrQuestion !== undefined ? corrQuestion : '',
          msgStatus: status,
          msgStatusLoading: loading,
        };
        return [...msgs, newMessage];
      } else {
        const lastMessage = msgs[msgs.length - 1];
        const lastMessageObj = lastMessage.msgObj as AiMessage;
        const newMessage: IMessageProps = {
          ...lastMessage,
          corrQuestion:
            corrQuestion !== undefined
              ? corrQuestion
              : lastMessage.corrQuestion,
          msgStatus: status !== undefined ? status : lastMessage.msgStatus,
          msgStatusLoading:
            loading !== undefined ? loading : lastMessage.msgStatusLoading,
          msgObj: {
            ...lastMessageObj,
            content:
              content !== undefined
                ? lastMessageObj.content + content
                : lastMessageObj.content,
            references:
              references !== undefined ? references : lastMessageObj.references,
          } as AiMessage,
        };
        // Update the last item in the array
        return [...msgs.slice(0, -1), newMessage];
      }
    });
  };

  const handleClearChat = () => {
    if (abortController.current) abortController.current.abort();
    setMessages([]);
    setErrMsg(null);
    chatId.current = '';
    setShowClearModal(false);
    setScenarioShow('skill_selected');
    setInputDisabled(false);
  };

  const handleError = (errorDetail: string, removeLastMsg?: boolean) => {
    const fullMsg = `${errorDetail}${errorDetail.endsWith('.') ? '' : '.'} **Please start a new topic.**`;
    setErrMsg(fullMsg);
    if (removeLastMsg === true) {
      setMessages((msgs) => msgs.slice(0, -1));
    }
  };

  const handleHumanReachout = () => {
    const conversation = messagesToConversation(messages, uuidv4());
    let commentData: CommentData = {
      id: uuidv4().toString(),
      user_id: '',
      answer_id: '',
      comment: '',
      question: '',
      answer: '',
      version: versionData?.version || '',
      is_like: false,
      dislike_reason: '',
      references: [],
      relatedDocuments: [],
      conversation: conversation as Conversation,
      human_reachout: true,
      selected_skill: skillData[selectedSkillIndex] === undefined ? '' : skillData[selectedSkillIndex].id,
    };
    setCommentData(commentData);
    setShowComment(true);
  };

  // 分頁
  const [currentShift, setCurrentShift] = useState(0);
  const isFirstItem = currentShift === 0;
  const isLastItem = currentShift >= skillData.length - 3;
  const pageItems = skillData.slice(currentShift, currentShift + 3);

  const handlePrevious = () => {
    if (!isFirstItem) {
      setCurrentShift(currentShift - 1);
    }
  };

  const handleNext = () => {
    if (!isLastItem) {
      setCurrentShift(currentShift + 1);
    }
  };
  // 分頁 End

  return (
    <Stack
      id="container"
      verticalFill
      styles={{
        root: {
          backgroundColor: '#FAFAFA',
          width: '100%',
          height: '100%',
        },
      }}
    >
      <SelectedSkillContext.Provider value={skillData[selectedSkillIndex]}>
      <NavigationBar scenario={scenarioShow} onContactEPSOSMEClick={() => handleHumanReachout()} />
      <Stack
        id="main"
        verticalFill
        styles={{ root: { width: '100%', height: '100%' } }}
      >
        {(scenarioShow === 'wait_select_skill' || scenarioShow === 'skill_selected') && (
          <Stack
            id="app-intro"
            horizontalAlign="center"
            verticalAlign="space-between"
            styles={{
              root: {
                width: `100%`,
                paddingTop: '2.31rem',
                paddingBottom: '2.44rem',
                "@media (max-width: 576px)": {
                  paddingLeft: '0rem',
                }
              },
            }}
          >
            <Stack
              id="app-title"
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: '1.44rem' }}
            >
              <Text
                data-testid="main-page-project-name"
                styles={{
                  root: {
                    fontSize: '2.5rem',
                    fontWeight: 600,
                    lineHeight: '3.25rem',
                    color: '#000000',
                  },
                }}
              >
                {APP_TITLE}
              </Text>
            </Stack>
            <Stack id="app-subtitle">
              <Text
                styles={{
                  root: {
                    fontSize: '1.25rem',
                    fontWeight: '600',
                    marginTop: '1rem',
                    lineHeight: '2.25rem',
                    color: '#000000',
                    "@media (max-width: 576px)": {
                      marginRight: '1.5rem',
                      marginLeft: '1.5rem',
                    }
                  },
                }}
              >
                Get answers to your Windows Server, Azure Stack HCI,
                or Windows IoT questions faster with our AI-powered assistant
              </Text>
            </Stack>
            <Text
              styles={{
                root: {
                  fontSize: '1.5rem',
                  fontWeight: '600',
                  marginTop: (scenarioShow === 'wait_select_skill' || windowsize[1] > 710) ? '5vh' : '0.5vh',
                  marginBottom: (scenarioShow === 'wait_select_skill' || windowsize[1] > 710) ? '4vh' : '0.5vh',
                  color: '#000000',
                },
              }}
            >
              {scenarioShow === 'wait_select_skill' ? 'Please select a technology below to get started:' : 'Available Skills'}
            </Text>
            {/* 3個 */}
            {skillData.length <= 3 && (
              <Stack
                id="skill-selector"
                horizontal
                verticalAlign="center"
                styles={{
                  root: {
                    margin: '0 auto',
                    alignItems: 'stretch',
                    "@media (max-width: 576px)": {
                      display: 'block'
                    }
                  },
                }}
              >
                {skillData.map((item, index) => (
                  <Stack
                    key={item.id}
                    data-testid={item.display_name + ' Skill'}
                    styles={{
                      root: {
                        flexFlow: 'nowrap',
                        "@media (max-width: 576px)": {
                          margin: '1rem 0.5rem'
                        }
                      },
                    }}
                    onClick={() => switchSkillSelect(index)}>
                    <SkillContainer
                      isNormalSize={true}
                      isSelect={selectedSkillIndex === index}
                      skillData={item}
                    />
                  </Stack>
                ))}
              </Stack>
            )}
            {/* 3個以上 */}
            {skillData.length > 3 && (
              <Stack
                id="skill-selector"
                horizontal
                verticalAlign="center"
                styles={{
                  root: {
                    margin: '0 2rem',
                  },
                }}
              >
                <Button
                  appearance="transparent"
                  size="large"
                  icon={<CaretLeft24Regular />}
                  onClick={() => handlePrevious()}
                  style={{ visibility: isFirstItem ? 'hidden' : 'unset' }}
                ></Button>
                {pageItems.map((item, index) => (
                  <Stack key={item.id} data-testid={item.display_name + ' Skill'} onClick={() => switchSkillSelect(index + currentShift)}>
                    <SkillContainer
                      isNormalSize={true}
                      isSelect={selectedSkillIndex === index + currentShift}
                      skillData={item}
                    />
                  </Stack>
                ))}
                <Button
                  appearance="transparent"
                  size="large"
                  icon={<CaretRight24Regular />}
                  onClick={() => handleNext()}
                  style={{ visibility: isLastItem ? 'hidden' : 'unset' }}
                ></Button>
              </Stack>
            )}
          </Stack>
        )}
        {scenarioShow === 'conversation' && (
          <Stack
            id="skill-selector-small"
            horizontal
            horizontalAlign="center"
            styles={{
              root: {
                "@media (max-width: 576px)": {
                  overflowX: 'scroll',
                  width: '95%',
                }
              }
            }}>
            {skillData.map((item, index) => (
              <Stack
                key={ item.id }
                data-testid={item.display_name + ' Skill Small'}
                styles={{
                  root: {
                    marginTop: windowsize[1] > 710 ? '2rem' : '1rem',
                    marginBottom: windowsize[1] > 710 ? '2rem' : '1rem',
                  },
                }}
                onClick={() => showSwitchSkillModal(index)}>
                <SkillContainer
                  isNormalSize={false}
                  isSelect={selectedSkillIndex === index}
                  skillData={item}
                />
              </Stack>
            ))}
          </Stack>
        )}

        {(scenarioShow === 'skill_selected' || scenarioShow === 'conversation') && (
          <Stack
            id="chat-area"
            horizontalAlign="center"
            verticalFill
            styles={{
              root: {
                width: `100%`,
                paddingLeft: '2.94rem',
                paddingRight: '2.94rem',
              },
            }}
          >
            {messages.length === 0 && (
              <FluentProvider
                id="sample-questions-area"
                theme={webLightTheme}
                className={styles.sampleQuestionsArea}
              >
                <div>
                  <SampleQuestionsContainer
                    questions={ skillData[selectedSkillIndex].sample_questions }
                    onClick={ handleInput }
                  />
                </div>
              </FluentProvider>
            )}

            {scenarioShow === 'conversation' && (
              <Stack styles={{
                root: {
                  width: '100%',
                  height: '100%',
                  paddingLeft: '15%',
                  paddingRight: '15%',
                  flex: '1 1 0',
                  '@media (max-width: 576px)': {
                    paddingLeft: '0',
                    paddingRight: '0',
                  }
                }
              }}>
                {messages.length > 0 && (<MessageContainer messages={messages} />)}
                {errMsg && (
                  <Stack.Item
                    styles={{
                      root: { maxWidth: '70%', marginBottom: '1rem' },
                    }}
                    align="center"
                  >
                    <ErrorMessage details={errMsg} />
                  </Stack.Item>
                )}
              </Stack>
            )}
            <Stack styles={{root: {flex: '0 0 auto', width: '100%'}}}>
              <UserInput
                scenario={scenarioShow as UIState}
                onInputSubmit={handleInput}
                waiting={inputDisabled}
                onInputClear={() => setShowClearModal(true)}
              />
            </Stack>
          </Stack>
        )}
        <Stack>
          <SimpleModal
            isOpen={showModal}
            modalTitle="Are you sure you want to switch skills?"
            modalData="Project Edgefield does not retain chat history, switching to a new topic or skill will start a new chat session. Are you sure you want to proceed?"
            submitBtnText="Change"
            onSubmit={() => handleSwitchSkillSubmit()}
            onClose={() => setShowModal(false)}
          />
          <SimpleModal
            isOpen={showClearModal}
            modalTitle="Are you sure you want to start a new topic?"
            modalData="Project Edgefield does not retain chat history, switching to a new topic or skill will start a new chat session. Are you sure you want to proceed?"
            submitBtnText="New Topic"
            onSubmit={() => handleClearChat()}
            onClose={() => setShowClearModal(false)}
          />
          <CommentModal
            isOpen={showComment}
            commentData={commentData}
            onCloseComment={() => setShowComment(false)}
            onCommentSubmitted={() => void 0}
          />
        </Stack>

        {scenarioShow === 'wait_select_skill' && (
          <Text
            styles={{
              root: {
                fontSize: '1.25rem',
                textAlign: 'center',
                marginTop: '4rem',
                marginBottom: '2rem',
              },
            }}
          >
            Data shared is Microsoft Confidential Information and shared under
            NDA.
          </Text>
        )}
        <Stack id="footer" styles={{root: {marginBottom: '2.3rem'}}}>
          <Text styles={{ root: { fontSize: '1.25rem', textAlign: 'center' } }}>
            Project Edgefield is still learning and may produce mistakes, please
            share your feedback to help us improve the experience{' '}
          </Text>
          <Footer />
        </Stack>
      </Stack>
      </SelectedSkillContext.Provider>
    </Stack>
  );
};
