import { Stack } from '@fluentui/react';
import {
  Field,
  FluentProvider,
  webLightTheme,
  Text,
  makeStyles,
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogContent,
  Button,
  shorthands
} from '@fluentui/react-components';

const useStyles = makeStyles({
  dialog: {
    maxWidth: '44%',
  },
  fieldLabel: {
    color: '#000',
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '1.375rem',
    marginTop: '3rem',
  },
  text: {
    fontSize: '1.3rem',
    fontWeights: '400',
    lineHeight: '1.525rem',
    marginTop: '1.5rem',
    marginBottom: '3.5rem',
  },
  errMsg: {
    color: 'red',
    fontSize: '1rem',
    fontWeights: '400',
    lineHeight: '1.125rem',
    wordWrap: 'break-word',
    overflowX: 'hidden',
  },
  submitBtn: {
    backgroundColor: '#174AE4',
    width: '11rem',
    height: '2.56rem',
    paddingLeft: '2.21rem',
    paddingRight: '2.21rem',
    paddingTop: '1.75rem',
    paddingBottom: '1.75rem',
    marginRight: '0.5rem',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight:'1rem'
  },
  cancelBtn: {
    ...shorthands.border('2px', 'solid', '#000000'),
    width: '11rem',
    height: '2.56rem',
    paddingLeft: '2.21rem',
    paddingRight: '2.21rem',
    paddingTop: '1.75rem',
    paddingBottom: '1.75rem',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight:'1rem'
  },
});

export interface ISimpleModalProps {
  isOpen: boolean;
  modalTitle: string;
  modalData: string;
  submitBtnText: string;
  onSubmit: () => void;
  onClose: () => void;
}

export const SimpleModal = ({
  isOpen,
  modalTitle,
  modalData,
  submitBtnText,
  onSubmit,
  onClose,
}: ISimpleModalProps) => {
  const styles = useStyles();

  return (
    <FluentProvider theme={webLightTheme}>
      <Dialog modalType="modal" open={isOpen}>
        <DialogSurface className={styles.dialog} data-testid="simple-modal">
          <DialogBody>
            <DialogTitle>{modalTitle}</DialogTitle>
            <DialogContent>
              <Field>
                <Text className={styles.text}>{modalData}</Text>
              </Field>
            </DialogContent>
          </DialogBody>
          <Stack horizontal styles={{
            root: {
              justifyContent: 'center'
            },
          }}>
            <Button
              className={styles.submitBtn}
              data-testid="simple-modal-submit-btn"
              type="submit"
              appearance="primary"
              shape="circular"
              onClick={() => onSubmit()}
            >
              {submitBtnText}
            </Button>
            <Button
              className={styles.cancelBtn}
              appearance="secondary"
              shape="circular"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </Stack>
        </DialogSurface>
      </Dialog>
    </FluentProvider>
  );
};
